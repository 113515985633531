.paymentLogo {
    height: 70px;
    width: 100px;
    margin: 20px;
    cursor: pointer;
    border-radius: 10px;
}

.paymentLogo:hover {
    border: 3px solid rgb(250, 242, 133);
    opacity: 0.7;
}

.selectedImg {
    position: absolute;
    top: 7px;
    left: 23px;
}

@media only screen and (max-width: 600px) {
    .paymentLogo {
        height: 40px;
        width: 60px;
    }

    .selectedImg {
        position: absolute;
        top: 10px;
        left: 40px;
    }
}